import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import theme from '../../theme'
import { EPProvider, Modal, Image, Copy } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import { getPrivacy as getPrivacyApi, setPrivacy as setPrivacyApi } from 'tos/js/services/tosChecker'
import { getCookiePrivacyAvoided as getCookiePrivacyAvoidedStorage, setCookiePrivacyAvoided as setCookiePrivacyAvoidedStorage } from 'tos/js/services/tosPrivacy'
import useApi from 'commons/js/hooks/useApi'
import QuestionContent from './components/QuestionContent'
import { injectIntl, FormattedMessage } from 'react-intl'
import { TRADENAME } from 'commons/js/constants'

const TOSAdvancedProfile = ({
    getPrivacyApi,
    setPrivacyApi,
    getCookiePrivacyAvoided,
    setCookiePrivacyAvoided,
    modalComponent: ModalComponent,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isAskAgain, setIsAskAgain] = useState(false)
    const [isPrivacyAvoided, setIsPrivacyAvoided] = useState(getCookiePrivacyAvoided())

    const {
        response: { result: getPrivacyResult, error: getPrivacyError },
        loading: getPrivacyLoading,
        sendRequest: requestGetPrivacy,
    } = useApi(getPrivacyApi)

    const {
        response: { result: savePrivacyResult, error: savePrivacyError },
        loading: savePrivacyLoading,
        sendRequest: savePrivacy,
    } = useApi(setPrivacyApi)

    const loading = getPrivacyLoading || savePrivacyLoading
    const error = getPrivacyError || savePrivacyError
    useEffect(() => {
        !isPrivacyAvoided && requestGetPrivacy()
    }, [isPrivacyAvoided, requestGetPrivacy])

    useEffect(() => {
        getPrivacyResult && setIsOpen(!getPrivacyResult.profileForAdvertising)
    }, [getPrivacyResult])

    useEffect(() => {
        savePrivacyResult && setIsOpen(!savePrivacyResult.profileForAdvertising)
    }, [savePrivacyResult])

    const onAvoidPrivacy = () => {
        setIsPrivacyAvoided(true)
        setIsOpen(false)
        setCookiePrivacyAvoided()
    }

    const onAcceptPrivacy = () => {
        savePrivacy({ profileForAdvertising: true })
    }

    return (
        <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
            <ModalComponent
              isShown={isOpen}
              showCloseButton={false}
              padding='20px 30px'
              width='700px'
              height={['100%', 'auto']}
              label=' '
              showModalTitle={false}
              zIndex={9999}
            >
                {isAskAgain
                    ? <QuestionContent
                        dataTest='ask-again-content'
                        error={error}
                        loading={loading}
                        onAccept={() => onAcceptPrivacy()}
                        onCancel={() => onAvoidPrivacy()}
                        title={<FormattedMessage defaultMessage='¡Recuerda, no podremos mandarte ofertas exclusivas!' />}
                        description={<Copy size='small' fontWeight='regular'><FormattedMessage defaultMessage='Nuestra intención es brindarte la mejor experiencia y para eso necesitamos conocerte y que nos tuteemos. Sino no recibirás ofertas exclusivas, no podremos personalizar tu experiencia y tampoco los servicios y ventajas.' /></Copy>}
                      />
                      : <QuestionContent
                          dataTest='question-content'
                          error={error}
                          loading={loading}
                          onAccept={() => onAcceptPrivacy()}
                          onCancel={() => setIsAskAgain(true)}
                          title={<FormattedMessage defaultMessage='Contenidos y ofertas exclusivas' />}
                          description={<>
                              <Copy size='small' fontWeight='regular'><FormattedMessage defaultMessage='Para mejorar tu experiencia en {tradename}, te planteamos poder hacer seguimiento de tus apps para tener más información sobre ti y poder personalizarte mejor el servicio.' values={{ tradename: TRADENAME }} /></Copy>
                              <Copy size='small' fontWeight='regular'><FormattedMessage defaultMessage='Tus datos solo se utilizarán para ofrecerte comunicaciones personalizadas y de tu máximo interés.' /></Copy>
                          </>}
                          image={<Image src='/images/privacy.svg' width='150px' height='150px' alignSelf='center' alt='ad' />}
                    />
                }
            </ModalComponent>
        </EPProvider>
    )
}

TOSAdvancedProfile.propTypes = {
    intl: PropTypes.object,
    setPrivacyApi: PropTypes.func,
    getPrivacyApi: PropTypes.func,
    defaultIsPrivacyAvoided: PropTypes.any,
    getCookiePrivacyAvoided: PropTypes.func,
    setCookiePrivacyAvoided: PropTypes.func,
    modalComponent: PropTypes.any,
}

TOSAdvancedProfile.defaultProps = {
    setPrivacyApi: setPrivacyApi,
    getPrivacyApi: getPrivacyApi,
    getCookiePrivacyAvoided: getCookiePrivacyAvoidedStorage,
    setCookiePrivacyAvoided: setCookiePrivacyAvoidedStorage,
    modalComponent: Modal,
}

export default injectIntl(TOSAdvancedProfile)
